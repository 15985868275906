import React from 'react'
import {
  Box,
  BoxProps,
  ResponsiveProp,
  normalizeResponsiveProp,
} from '@walltowall/calico'
import * as RA from 'fp-ts/es6/ReadonlyArray'
import * as R from 'fp-ts/es6/Record'

export const variants = {
  extraNarrow: {
    paddingLeft: [4, 10, 19, 28],
    paddingRight: [4, 10, 19, 28],
    paddingTop: [6, 7, 8],
    paddingBottom: [6, 7, 8],
  },
  narrow: {
    paddingLeft: [6, 10, 19, 28],
    paddingRight: [6, 10, 19, 28],
    paddingTop: [10, 10, 13],
    paddingBottom: [10, 10, 13],
  },

  base: {
    paddingLeft: [6, 10, 19, 28],
    paddingRight: [6, 10, 19, 28],
    paddingTop: [10, 20, 28],
    paddingBottom: [10, 20, 28],
  },
  wide: {
    paddingLeft: [6, 10, 19, 28],
    paddingRight: [6, 10, 19, 28],
    paddingTop: [12, 25, 46],
    paddingBottom: [12, 25, 46],
  },

  short: {
    paddingLeft: [6, 10, 19, 28],
    paddingRight: [6, 10, 19, 28],
    paddingTop: [10, 20, 22],
    paddingBottom: [10, 20, 22],
  },
} as const

type BoundedBoxProps = {
  children?: React.ReactNode
  variant?: keyof typeof variants
  innerMaxWidth?: NonNullable<BoxProps['styles']>['maxWidth']
  nextSharesBg?: ResponsiveProp<boolean>
} & BoxProps

export const BoundedBox = ({
  children,
  variant: variantName = 'base',
  innerMaxWidth,
  nextSharesBg = false,
  styles = {},
  ...props
}: BoundedBoxProps) => {
  const variant = variants[variantName]

  const { paddingBottom, ...restStyles } = styles

  const resolvedPaddingBottom = RA.zipWith(
    normalizeResponsiveProp(nextSharesBg),
    normalizeResponsiveProp(paddingBottom || variant.paddingBottom),
    (a, b) => (a ? 0 : b),
  ) as NonNullable<BoxProps['styles']>['paddingBottom']

  return (
    <Box
      {...props}
      styles={{
        paddingLeft: variant.paddingLeft,
        paddingRight: variant.paddingRight,
        paddingTop: variant.paddingTop,
        paddingBottom: resolvedPaddingBottom,
        ...restStyles,
      }}
    >
      <Box
        styles={{
          maxWidth: innerMaxWidth,
          width: 'full',
          marginLeft: 'auto',
          marginRight: 'auto',
          height: 'full',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {children}
      </Box>
    </Box>
  )
}
