import React from 'react'
import { graphql } from 'gatsby'
import { getRichText } from '@walltowall/helpers'
import { AspectRatio } from '@walltowall/siamese'
import { Box } from '@walltowall/calico'

import { PageBodyHeroFragment } from '../graphqlTypes'
import { MapDataToPropsArgs } from '../types'
import { PageTemplateEnhancerProps } from '../templates/page'

import { HTMLContent } from '../components/HTMLContent'
import { Text } from '../components/Text'
import { BoundedBox, variants as bBoxVariants } from '../components/BoundedBox'

import * as styleRefs from './PageBodyHero.treat'
import { VideoPlayer } from '../components/VideoPlayer'
import { Button } from '../components/Button'

export type PageBodyHeroProps = ReturnType<typeof mapDataToProps> &
  PageTemplateEnhancerProps

const PageBodyHero = ({
  textHTML,
  videoURL,
  buttonText,
  buttonHref,
  buttonTarget,
}: PageBodyHeroProps) => {
  return (
    <Box
      component="section"
      styles={{
        backgroundColor: 'white',
        maxWidth: 'xlarge',
        marginRight: 'auto',
        marginLeft: 'auto',
      }}
    >
      <BoundedBox
        className={styleRefs.diamondBackground}
        styles={{
          paddingBottom: 0,
          backgroundColor: 'gold70',
          color: 'brown20',
        }}
      >
        {textHTML && (
          <Box
            styles={{
              //@ts-ignore
              marginLeft: bBoxVariants.base.paddingLeft.map((n) => -1 * n),
              paddingLeft: bBoxVariants.base.paddingLeft,
              //@ts-ignore
              marginRight: bBoxVariants.base.paddingRight.map((n) => -1 * n),
              paddingRight: bBoxVariants.base.paddingRight,
              //@ts-ignore
              marginTop: bBoxVariants.base.paddingTop.map((n) => -1 * n),
              paddingTop: bBoxVariants.base.paddingTop,
            }}
          >
            <HTMLContent
              html={textHTML}
              styles={{
                maxWidth: '60rem',
                marginLeft: 'auto',
                marginRight: 'auto',
                marginBottom: 10,
              }}
              componentOverrides={{
                h1: () => (props) =>
                  (
                    <Text
                      component="h2"
                      variant="sans-38-52"
                      styles={{ color: 'brown20', marginBottom: [7, 8, 10] }}
                      {...props}
                    />
                  ),
                p: (Comp) => (props) =>
                  (
                    <Comp
                      variant="sans-18-28"
                      style={{ maxWidth: '55ch' }}
                      {...props}
                    />
                  ),
              }}
            />

            {buttonHref && (
              <Box
                styles={{
                  display: 'flex',
                  justifyContent: 'start',
                  maxWidth: '60rem',
                  marginLeft: 'auto',
                  marginRight: 'auto',
                  marginBottom: [12, 16, 20],
                }}
              >
                <Button
                  variant="red"
                  href={buttonHref}
                  target={buttonTarget}
                  component="a"
                  rel="noopener noreferrer nofollow"
                >
                  {buttonText}
                </Button>
              </Box>
            )}
          </Box>
        )}

        {videoURL && (
          <VideoPlayer
            autoplay={true}
            loop={true}
            videoProps={{
              muted: true,
              playsinline: true,
              controls: false,
            }}
            videoURL={videoURL}
            x={16}
            y={9}
            styles={{
              display: ['block', 'none'],
              backgroundColor: 'brown20',
              marginLeft: [-6, 0],
              marginRight: [-6, 0],
              position: 'relative',
            }}
          />
        )}

        {videoURL && (
          <VideoPlayer
            autoplay={true}
            loop={true}
            videoProps={{
              muted: true,
              playsinline: true,
              controls: false,
            }}
            videoURL={videoURL}
            x={16}
            y={9}
            styles={{
              display: ['none', 'block'],
              backgroundColor: 'brown20',
              marginLeft: [-6, 0],
              marginRight: [-6, 0],
              position: 'relative',
            }}
            className={styleRefs.imageBleedOffset}
          />
        )}
      </BoundedBox>

      {videoURL && (
        <BoundedBox
          styles={{
            display: ['none', 'block'],
            paddingTop: 0,
            paddingBottom: 0,
          }}
        >
          <AspectRatio x={16} y={4.5} />
        </BoundedBox>
      )}
    </Box>
  )
}

export const mapDataToProps = ({
  data,
}: MapDataToPropsArgs<PageBodyHeroFragment, typeof mapDataToContext>) => ({
  textHTML: getRichText(data.primary?.text),
  videoURL: data.primary?.video_url,
  buttonText: data.primary?.button_text,
  buttonHref: data.primary?.button_link?.url,
  buttonTarget: data.primary?.button_link?.target,
})

export const mapDataToContext = () => ({
  bg: ['gold70', null, 'white'],
})

export const fragment = graphql`
  fragment PageBodyHero on PrismicPageBodyHero {
    primary {
      text {
        text
        html
      }
      video_url
      button_text
      button_link {
        url
        target
      }
    }
  }
`

export default PageBodyHero
